@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

//sidebar css
.side-menu-icon {
	position: relative;
}
.mobile-menu.mean-container {
	clear: both;
	overflow: hidden;
}
.contact-infos {
	margin-top: 30px;
    & h4 {
        font-size: 27px;
        color: $white-color;
        margin-bottom: 20px;
    }
    & ul {
        & li,
        & li a {
            font-size: 15px;
            color: $white-color;
            margin-bottom: 15px;
            list-style: none;
            &:last-child {
                margin-bottom: 0px;
            }
            & img {
                width: 16px;
                margin-right: 8px;
            }
        }
    }
}
.sidebar__menu--social {
	margin-top: 25px;
    & a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        margin-right: 5px;
        background: #393f53;
        color: $white-color;
        position: relative;
        z-index: 1;
        overflow: hidden;
        border-radius: 5px;
        &:before {
            position: absolute;
            content: "";
            top: 0px;
            left: 0px;
            height: 100%;
            width: 0%;
            background: $theme-color;
            z-index: -1;
            transform: rotate(90deg);
            @include transition(.3s);
        }
        & i {
            transform: rotate(0deg);
            @include transition(.3s);
        }
        &:hover {
            &:before {
                width: 100%;
            }
            & i {
                transform: rotate(360deg);
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
}
.side-menu-icon i {
    color: $white-color;
}
.side-toggle2 i {
    color: $heading-color;
}

.side-info {
    background: #25262A;
	width: 300px;
	height: 100%;
	position: fixed;
	z-index: 9999;
	right: -100%;
	top: 0;
	padding: 30px;
	transition: .6s;
	overflow-y: scroll;
	&.info-open{
		right: 0;
		visibility: visible;
		opacity: 1;
	}
}
.side-toggle {
    font-size: 32px;
    color: $white-color;
    background: none;
    border: none;
}
.side-info-close {
    font-size: 26px;
    color: $white-color;
    background: none;
    border: none;
}
.body-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
    left: 0;
	opacity: 0;
	visibility: hidden;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
	transition: all 0.3s linear 0s;

	&.show {
		z-index: 999;
		opacity: 1;
		visibility: visible;
        display: block;
	}
}

/* mobile menu */

.menu-bar .bars {
    display: inline-block;
    width: 50px;
    background: #212121;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border: 0;
    font-size: 20px;
    color: #fff;
}

.close-mobile-menu {
	color: #212121;
	position: relative;
	z-index: 2;
	font-size: 16px;
	top: -10px;
	left: 0;
}

.mm-menu{
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			display: block;
			&:hover{
				> a{
					padding-left: 3px;
				}
			}
			a {
				padding: 12px 0;
				display: block;
				border-bottom: 1px solid rgba(0, 0, 0, 0.05);
				font-size: 14px;
				color: #fff !important;
				font-weight: 600;
				text-transform: uppercase;
				position: relative;
				cursor: pointer;
				
				& span.active {
                    color: $theme-color;
                }
			}

			a.active{
				padding-left: 3px;
			}
		}
		
		li.has-droupdown{
			> a{
				&:after{
					position: absolute;
					content: "";
					width: 8px;
					height: 8px;
					border-width: 2px 0 0 2px;
					border-style: solid;
					border-color: initial;
					right: 16px;
					top: 50%;
					-webkit-transform: rotate(-45deg) translateY(-50%);
					transform: rotate(-45deg) translateY(-50%);
					-webkit-transform-origin: top;
					transform-origin: top;
					transition: all 0.3s ease-out;
				}
			}
			ul.sub-menu{
				padding-left: 0;
				list-style: none;
				li{
					padding-left: 15px;
					&:hover{
						> a{
							padding: 0 0 0 18px;
							&:before{
								background: #6d8397;
								border-color: #6d8397;
							}
						}
					}
					a{
						position: relative;
						padding: 0 0 0 15px;
						text-transform: capitalize;
						font-size: 13px;
						height: 0;
						line-height: 46px;
						visibility: hidden;
						opacity: 0;
						transition: all 0.3s;
						color: rgba(255,255,255,0.6) !important;

						&:before{
							content: "";
							width: 8px;
							height: 8px;
							position: absolute;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							border: 2px solid #6d8397;
							border-radius: 50%;
							visibility: hidden;
							opacity: 0;
							transition: all 0.3s;
						}
					}
				}
			}
		}

		li.has-droupdown.active{
			> a{
				&:after{
					-webkit-transform: rotate(-135deg) translateY(-50%);
					transform: rotate(-135deg) translateY(-50%);
				}
			}
			ul.sub-menu.active{
				li{
					a{
						height: 46px;
						visibility: visible;
						opacity: 1;
						&:before{
							visibility: visible;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
.sidebar__areas.open .cartmini__wrapper {
	right: 0px;
}
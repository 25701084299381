@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?57bf9b0f0f38191f307261b9c70a5c3c") format("truetype"),
url("../fonts/flaticon.woff?57bf9b0f0f38191f307261b9c70a5c3c") format("woff"),
url("../fonts/flaticon.woff2?57bf9b0f0f38191f307261b9c70a5c3c") format("woff2"),
url("../fonts/flaticon.eot?57bf9b0f0f38191f307261b9c70a5c3c#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?57bf9b0f0f38191f307261b9c70a5c3c#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-analytics:before {
    content: "\f101";
}
.flaticon-stats:before {
    content: "\f102";
}
.flaticon-marketing:before {
    content: "\f103";
}
.flaticon-digital-marketing:before {
    content: "\f104";
}
.flaticon-idea:before {
    content: "\f105";
}
.flaticon-social-media:before {
    content: "\f106";
}
.flaticon-goal:before {
    content: "\f107";
}
.flaticon-development:before {
    content: "\f108";
}
.flaticon-handshake:before {
    content: "\f109";
}
.flaticon-team:before {
    content: "\f10a";
}
.flaticon-review:before {
    content: "\f10b";
}
.flaticon-talent:before {
    content: "\f10c";
}
.flaticon-group:before {
    content: "\f10d";
}
.flaticon-chat:before {
    content: "\f10e";
}
.flaticon-envelope:before {
    content: "\f10f";
}
.flaticon-placeholder:before {
    content: "\f110";
}
.flaticon-profile:before {
    content: "\f111";
}
.flaticon-website:before {
    content: "\f112";
}
.flaticon-reaction:before {
    content: "\f113";
}
.flaticon-trend:before {
    content: "\f114";
}
.flaticon-art-palette:before {
    content: "\f115";
}

@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 19. Pricing */

.pricing-wrapper {
    background: $white-color;
    border-radius: 8px;
    padding: 50px 20px 20px 20px;
    border: 1px solid rgba(255, 64, 64,1);
    &:hover {
        & .pricing-icon {
            background: rgba(255, 64, 64,1);
            & span {
                color: $white-color;
            }
        }
    }
}
.pricing-icon {
    height: 120px;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    background: rgba(255, 64, 64,0.3);
    display: inline-block;
    @include transition(0.3s);
    vertical-align: middle;
    & span {
        font-size: 36px;
        color: $heading-color;
        @include transition(0.3s);
    }
}
.pricing-subtitle {
    font-size: 30px;
    text-transform: uppercase;
    color: rgba(255, 64, 64,1);
    letter-spacing: 2px;
    margin-bottom: 0px;
    @include mq(lg) {
        font-size: 250px;
    }
    @include mq(xs) {
        font-size: 20px;
    }
}
.pricing-title {
    font-size: 60px;
    margin-bottom: 0px;
    @include mq(lg) {
        font-size: 50px;
    }
    @include mq(xs) {
        font-size: 50px;
    }
}
.pricing-list {
    display: inline-block;
    text-align: left;
    & ul {
        & li {
            font-size: 16px;
            color: $body-color;
            list-style: none;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            & i {
                font-size: 15px;
                color: $theme-color;
                width: 20px;
                display: inline-block;
                transform: translateY(1px);
                &.cross-icon {
                    color: $body-color;
                }
            }
        }
    }
}
.pricing-list-bg {
    background: $white-color;
    border-radius: 8px;
    padding: 35px 15px 30px 15px;
}
.pricing-btn {
    & .theme-btn {
        position: relative;
        display: inline-block;
        font-size: 18px;
        letter-spacing: .1em;
        z-index: 1;
        border-radius: 5px;
        @include mq(lg){
            padding: 0px 25px;  
        }
        @include mq(md) {
            padding: 0px 35px;
        }
        @include mq(xs){
            padding: 0px 25px;  
        }
         @include mq(sm) {
            padding: 0px 35px;
        }
        &:before {
            background:  $grey-color;
        }
    }
}

.tp-pricing-tabs {
    display: inline-flex;
    padding: 5px;
    background: #f2f3f5;
    border-radius: 30px;
    & button {
        height: 50px;
        line-height: 50px;
        padding: 0px 30px;
        background: rgba(255, 64, 64, 0.3);
        display: inline-block;
        border-radius: 30px;
        font-weight: 500;
        font-size: 16px;
        color: #09150f;
        border: none;
        outline: none;
        cursor: pointer;
        @include mq(md) {
            padding: 0px 22px;
        }
        &:last-child {
            margin-left: 6px;
        }
        &.active {
            background: #e23;
            color: #fff;
            &:hover,
            &:focus {
                color: #fff;
            }
        }
    }
    .nav-link:hover, .nav-link:focus {
        color: #09150f;
    }
}
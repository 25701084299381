
@function _rgba($color, $opacity: 1) {
  @return unquote("rgba(#{red($color)}, #{green($color)}, #{blue($color)}, #{$opacity})");
}
@function _rem($target, $context: 16) {
  @return ($target / $context) * 1rem;
}

@mixin transition($time) {
  -webkit-transition: all $time linear 0s;
  -moz-transition: all $time linear 0s;
  -o-transition: all $time linear 0s;
  transition: all $time linear 0s;
}
@mixin transform($value) {
  -webkit-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin mq($value) {
  @if $value=='xxl' {
    @media (min-width: 1400px) and (max-width: 1700px) {
      @content;
    }
  }
  @if $value=='xl' {
    @media (min-width: 1200px) and (max-width: 1399px) {
      @content;
    }
  }
  @if $value=='lg' {
    @media (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }
  @if $value=='md' {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  @if $value=='xs' {
    @media (max-width: 767px) {
      @content;
    }
  }
  @if $value=='sm' {
    @media (min-width: 480px) and (max-width: 767px) {
      @content;
    }
  }
}
@mixin cmq($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin cmmq($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin cmaq($max) {
  @media (max-width: $max) {
    @content;
  }
}


// @include mq(xl){
                    
// }
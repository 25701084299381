$body-fonts: 'Roboto', sans-serif;
$icon-font: "Font Awesome 5 Pro";

$body-fz: 14px;
$body-color: #777a7e;
$heading-color: #242629;
$theme-color: #fe0000;
$white-color: #fff;
$grey-color: #f2f3f5;
$blue-color: #3994d4; 
$border-color: #e9ebed; 


@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 15. Benifit */
.benefit-text {
    position: relative;
    & .benefit-subtitle {
        font-size: 18px;
        color: #1a1c1e;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    & p {
        font-size: 16px;
        line-height: 2;
    }
}
.benefit-img {
    position: absolute;
    top: -80px;
    right: 0px;
    z-index: 2;
    & img{
      -webkit-filter: grayscale(100%);
      @include transition (.3s);
    }
    &:hover{
      & img{
        -webkit-filter: grayscale(0);
      }
    }
    @include mq(xxl){
      width: 45%;
    }
    @include mq(xl){
      width: 45%;
      top: 120px;
    }
    @include mq(lg){
      position: relative;
      margin-top: -75px;
      & img {
        width: 100%;
  }
}
@include mq(md){
  position: relative;
  margin-top: -75px;
  & img {
    width: 100%;
  }
}
@include mq(xs){
  position: relative;
  margin-top: -75px;
  & img {
    width: 100%;
  }
}
}
.benefit-img-level {
    position: absolute;
    left: -202px;
    bottom: 202px;
    transform: rotate(-90deg);
    @include mq(xl){
     left: -152px;
     bottom: 152px;
    }
    @include mq(lg){
      left: -120px;
      bottom: 152px;
     }
     @include mq(md){
      left: -120px;
      bottom: 152px;
     }
     @include mq(xs){
      display: none;
     }
    & span {
        font-size: 14px;
        font-weight: 700;
        color: $white-color;
        display: inline-block;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        padding: 15px 100px;
        background: $theme-color;
        @include mq(xl){
          padding: 15px 50px;
         }
         @include mq(lg){
          padding: 15px 50px;
          }
          @include mq(md){
            padding: 15px 50px;
          }
    }
}
.progress_circle {
  display: flex;
  align-items: center;
  h5 {
    padding-left: 20px;
  }
  .progress {
    width: 110px;
    height: 110px !important;
    float: left;
    line-height: 110px;
    background: none;
    margin: 0px 0px;
    box-shadow: none;
    position: relative
  }
  .progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #e9ebed;
    position: absolute;
    top: 0;
    left: 0
  }
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1
  }
  .progress .progress-left {
    left: 0
  }
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0
  }
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left
  }
  .progress .progress-right {
    right: 0
  }
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards
  }
  .progress .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: transparent;
    font-size: 24px;
    color: #1a1c1e;
    line-height: 100px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%
  }
  .progress.blue .progress-bar {
    border-color: $theme-color;
  }
  .progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s
  }
  .progress.yellow .progress-bar {
    border-color: $theme-color;
  }
  .progress.yellow .progress-right .progress-bar {
    animation: loading-3 1.8s linear forwards
  }
  .progress.yellow .progress-left .progress-bar {
    animation: none
  }
}

@keyframes loading-1 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg)
  }
}

@keyframes loading-2 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg)
  }
}

@keyframes loading-3 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  100% {
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg)
  }
}
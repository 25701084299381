@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

h6 {
  font-size: 18px;
}

.heading {
  padding-left: 28px;
}
.heading h2 {
  color: #000000;
  font-size: 23px;
  line-height: 1.3em;
  font-weight: 700;
}
.heading h6 {
  font-size: 18px;
  line-height: 1.3em;
  font-weight: 700;
}
@media (max-width: 992px) {
  .heading h2 {
    font-size: 30px;
  }
}
.heading.line {
  position: relative;
}
.heading.line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 28px;
  background-color: #ed1c24;
}

/* 01. emergency button message area start */
.emergency-button-message {
  text-align: center;
  &.pt-70 {
    @include mq(lg) {
      padding-top: 70px;
    }
    @include mq(md) {
      padding-top: 70px;
    }
    @include mq(xs) {
      padding-top: 70px;
    }
  }
  &.pb-35 {
    @include mq(lg) {
      padding-bottom: 35px;
    }
    @include mq(md) {
      padding-bottom: 35px;
    }
    @include mq(xs) {
      padding-bottom: 35px;
    }
  }
  .section-subtitle-wrapper {
    font-size: 20px;
  }
}

.emergency-button-message {
  & .common-shape-wrapper {
    left: 0px;
    right: auto;
    border-radius: 0px;
    border-top-right-radius: 100%;
    & .common-shape-inner {
      left: 0px;
      right: auto;
      border-radius: 0px;
      border-top-right-radius: 100%;
    }
  }
}

/* 02. VMS Contact Area */
.vms-contact-area {
  position: relative;
  @include mq(md) {
    padding-top: 110px;
  }
  @include mq(xs) {
    padding-top: 110px;
  }
  & img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.vms-contact-title {
  font-size: 34px;
  color: $white-color;
  font-weight: 400;
  @include mq(md) {
    font-size: 28px;
  }
  @include mq(xs) {
    font-size: 24px;
  }
  a {
    font-weight: 600;
  }
}
.vms-contact-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: -1100;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s linear 0s;
  -moz-transition: all 8s linear 0s;
  -o-transition: all 8s linear 0s;
  transition: all 8s linear 0s;
}
.vms-contact-bg::before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

/* 03. VMS Riders Safe Area */
.rider-section {
  padding: 60px 0px 20px 0px;
  position: relative;
  background-color: #f6f6f6;
  overflow: hidden;
  background-attachment: fixed;
}
@media (max-width: 1280px) {
  .rider-section {
    padding: 70px 0;
  }
}
.rider-section:before {
  content: "";
  position: absolute;
  left: 35px;
  top: 155px;
  width: 580px;
  height: 502px;
  background-image: url("../../../public/assets/img/vms/rider-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1400px) {
  .rider-section:before {
    width: 400px;
    height: 340px;
  }
}
@media (max-width: 1280px) {
  .rider-section:before {
    width: 350px;
    height: 280px;
  }
}
@media (max-width: 992px) {
  .rider-section:before {
    display: none;
  }
}
.rider-section:after {
  content: "";
  position: absolute;
  left: 186px;
  top: -52px;
  width: 185px;
  height: 95px;
}
@media (max-width: 1280px) {
  .rider-section:after {
    left: 116px;
  }
}
@media (max-width: 992px) {
  .rider-section:after {
    display: none;
  }
}
.rider-section .wrapper {
  width: 643px;
  margin: 0 0 0 auto;
}
@media (max-width: 992px) {
  .rider-section .wrapper {
    width: 100%;
  }
}
.rider-section .wrapper .list {
  padding-top: 70px;
  counter-reset: section;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style-type: none;
}
.rider-section .wrapper .list li {
  width: 48%;
  padding-left: 62px;
  position: relative;
  color: #333;
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 400;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .rider-section .wrapper .list li {
    width: 100%;
  }
}
.rider-section .wrapper .list li:before {
  counter-increment: section;
  content: counters(section, ".") " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  color: #fff;
  background-color: #ed1c24;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}

/* 03. VMS System Section */
.system-section {
  background-color: $white-color;
  z-index: 0;
  padding: 95px 0 130px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 992px) {
  .system-section {
    padding: 70px 0 60px;
  }
}
.system-section:after {
  content: "";
  position: absolute;
  right: 30px;
  top: 162px;
  width: 730px;
  height: 525px;
  background-image: url("../../../public/assets/img/vms/system-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1560px) {
  .system-section:after {
    width: 620px;
    height: 430px;
  }
}
@media (max-width: 1080px) {
  .system-section:after {
    display: none;
  }
}
.system-section .heading {
  margin-bottom: 73px;
}
.system-section .box {
  width: 675px;
  margin: 0 auto 0 0;
}
@media (max-width: 1430px) {
  .system-section .box {
    width: 525px;
  }
}
@media (max-width: 1280px) {
  .system-section .box {
    width: 435px;
  }
}
@media (max-width: 1080px) {
  .system-section .box {
    width: 100%;
  }
}
.system-section .box.second {
  width: 1005px;
  margin: 0 auto 0 0;
  padding-top: 85px;
}
@media (max-width: 1280px) {
  .system-section .box.second {
    width: 100%;
    padding-top: 50px;
  }
}
.system-section .box h5 {
  color: #000000;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 700;
  margin-bottom: 32px;
}
.system-section .box ul li {
  margin-bottom: 30px;
  color: #333;
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 400;
  padding-left: 25px;
  position: relative;
  list-style-type: none;
}
.system-section .box ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #ed1c24;
}

.benefit-section {
  padding: 140px 0 135px;
  background-attachment: fixed;
  background-position: center center;
}
@media (max-width: 767px) {
  .benefit-section {
    padding: 40px 0;
  }
}
.benefit-section .heading {
  margin-bottom: 52px;
}
@media (max-width: 767px) {
  .benefit-section .heading {
    margin-bottom: 40px;
  }
}
.benefit-section .heading h2 {
  color: #fff;
}
.benefit-section .row {
  justify-content: space-between;
}
.benefit-section .col-left,
.benefit-section .col-right {
  width: 50%;

  &.pr-0 {
    padding-right: 0;
  }

  &.pl-0 {
    padding-left: 0;
  }

  &.pr-30 {
    padding-right: 30px;
  }

  &.pl-30 {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .benefit-section .col-left,
  .benefit-section .col-right {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .benefit-section .col-right {
    margin-top: 30px;
  }
}
.benefit-section h3 {
  color: #fff;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 700;
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .benefit-section h3 {
    font-size: 22px;
  }
}
.benefit-section ul li {
  padding-left: 26px;
  position: relative;
  margin-bottom: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4em;
  color: #fff;
  list-style-type: none;
}
.benefit-section ul li:last-of-type {
  margin-bottom: 0;
}
.benefit-section ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #ed1c24;
}
.benefit-section .btn-wrapper {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1280px) {
  .benefit-section .btn-wrapper {
    padding-top: 50px;
  }
}
@media (max-width: 992px) {
  .benefit-section .btn-wrapper {
    padding-top: 35px;
  }
}
@media (max-width: 550px) {
  .benefit-section .btn-wrapper {
    flex-direction: column;
  }
}
.benefit-section .btn-wrapper .btn {
  margin: 0 25px;
  font-weight: 400;
  letter-spacing: 1px;
}
.benefit-section .btn-wrapper .btn:last-of-type {
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
}
@media (max-width: 550px) {
  .benefit-section .btn-wrapper .btn:last-of-type {
    margin-top: 20px;
  }
}
.benefit-section .btn-wrapper .btn:last-of-type:hover {
  background-color: #000000;
  color: #ed1c24;
  border: solid 1px #ed1c24;
}

.faq {
  padding: 135px 0 105px;
  background-color: #f6f6f6;
}
@media (max-width: 767px) {
  .faq {
    padding: 50px 0;
  }
}
.faq .tab-section {
  margin-top: 75px;
}
@media (max-width: 767px) {
  .faq .tab-section {
    margin-top: 40px;
  }
}
.faq .tab-section .tab-row {
  margin-bottom: 1px;
  background-color: #fff;
}
.faq .tab-section .tab-row.active .tab-head:before {
  content: "";
}
.faq .tab-section .tab-row .tab-head {
  padding: 45px 48px 45px 58px;
  cursor: pointer;
  position: relative;
}
@media (max-width: 992px) {
  .faq .tab-section .tab-row .tab-head {
    padding: 30px 30px 30px 40px;
  }
}
@media (max-width: 767px) {
  .faq .tab-section .tab-row .tab-head {
    padding: 20px;
  }
}
.faq .tab-section .tab-row .tab-head:before {
  content: "";
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: #ed1c24;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: fontawesome;
  color: #fff;
}
@media (max-width: 767px) {
  .faq .tab-section .tab-row .tab-head:before {
    right: 20px;
  }
}
.faq .tab-section .tab-row .tab-head h3 {
  color: #000000;
  font-size: 23px;
  line-height: 1.3em;
  font-weight: 700;
}
@media (max-width: 992px) {
  .faq .tab-section .tab-row .tab-head h3 {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .faq .tab-section .tab-row .tab-head h3 {
    font-size: 15px;
  }
}
.faq .tab-section .tab-row .tab-content {
  padding: 0 72px 75px 58px;
  display: none;
}
@media (max-width: 992px) {
  .faq .tab-section .tab-row .tab-content {
    padding: 0 50px 50px 40px;
  }
}
.faq .tab-section .tab-row .tab-content p {
  color: #333;
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
}
.faq .tab-section .tab-row .tab-content p:last-of-type {
  margin-bottom: 0;
}

.installation {
  background-color: $white-color;
  padding: 135px 0 85px;
}
@media (max-width: 1280px) {
  .installation {
    padding: 70px 0;
  }
}
.installation .row {
  justify-content: space-between;
  align-items: flex-end;
}
.installation .col-left {
  width: calc(100% - 625px);
}
@media (max-width: 1280px) {
  .installation .col-left {
    width: 48%;
  }
}
@media (max-width: 992px) {
  .installation .col-left {
    width: 100%;
  }
}
.installation .col-right {
  width: 605px;
  padding-bottom: 30px;
}
@media (max-width: 1280px) {
  .installation .col-right {
    width: 48%;
  }
}
@media (max-width: 992px) {
  .installation .col-right {
    width: 100%;
  }
}
.installation .heading {
  margin-bottom: 45px;
}
.installation ul li {
  padding-left: 26px;
  position: relative;
  margin-bottom: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4em;
  color: #333;
  list-style: none;
}
.installation ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #ed1c24;
}

.elev8-section {
  padding: 140px 135px;
  background-attachment: fixed;
  background-position: center center;
  padding: 50px 50px 50px 50px;
  background-color: #5a5a5a;

  .container-sm {
    p {
      color: #fff;
      font-size: 35px;
      font-weight: 500;
      text-align: center;
    }

    .elev8-description {
      color: #fff;
      font-size: 25px;
      font-weight: 500;
      text-align: center;
    }
  }
}
@media (max-width: 767px) {
  .elev8-section {
    padding: 40px 0;
  }
}
.elev8-section .heading {
  margin-bottom: 78px;
}
@media (max-width: 767px) {
  .elev8-section .heading {
    margin-bottom: 40px;
  }
}
.elev8-section .heading h2 {
  color: #fff;
}
.elev8-section .row {
  justify-content: space-between;
}
.elev8-section .col-left,
.elev8-section .col-right {
  width: 48%;
}
@media (max-width: 767px) {
  .elev8-section .col-left,
  .elev8-section .col-right {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elev8-section .col-right {
    margin-top: 30px;
  }
}
.elev8-section h3 {
  color: #fff;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 700;
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .elev8-section h3 {
    font-size: 22px;
  }
}
.elev8-section ul li {
  padding-left: 26px;
  position: relative;
  margin-bottom: 28px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4em;
  color: #fff;
  list-style-type: none;
}
.elev8-section ul li:last-of-type {
  margin-bottom: 0;
}
.elev8-section ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #ed1c24;
}
.elev8-section .btn-wrapper {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1280px) {
  .elev8-section .btn-wrapper {
    padding-top: 50px;
  }
}
@media (max-width: 992px) {
  .elev8-section .btn-wrapper {
    padding-top: 35px;
  }
}
@media (max-width: 550px) {
  .elev8-section .btn-wrapper {
    flex-direction: column;
  }
}
.elev8-section .btn-wrapper .btn {
  margin: 0 25px;
  font-weight: 400;
  letter-spacing: 1px;
}
.elev8-section .btn-wrapper .btn:last-of-type {
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
}
@media (max-width: 550px) {
  .elev8-section .btn-wrapper .btn:last-of-type {
    margin-top: 20px;
  }
}
.elev8-section .btn-wrapper .btn:last-of-type:hover {
  background-color: #000000;
  color: #ed1c24;
  border: solid 1px #ed1c24;
}

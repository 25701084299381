@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

.signin-area-wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
}
.signup-area-wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
}
.signup-wrapper {
	position: relative;
	input {
		width: 100%;
		height: 55px;
		background: #f5f5f5;
		border: 0;
		border-radius: 4px;
		margin-bottom: 30px;
		display: flex;
		padding: 10px 20px;
		outline: none;
		color: $body-color;
		font-size: 16px;
	}
}
.signup-input-wrapper {
	display: flex;
	justify-content: space-between;
	gap: 20px;
    @include mq(md){
        display: inherit;
    }
    @include mq(xs){
        display: inherit;
    }
    @include mq(sm){
        display: inherit;
    }
}
.signup-input-wrapper {
	input {
		width: 100%;
		height: 55px;
		background: #f5f5f5;
		border: 0;
		border-radius: 4px;
		margin-bottom: 30px;
		display: flex;
		padding: 10px 20px;
		outline: none;
		color: $body-color;
		font-size: 16px;
	}
}
.sign-check {
	a {
		color: $theme-color;
		font-weight: 700;
		text-decoration: underline;
		&:hover {
			color: $heading-color;
		}
	}
}
.signup-box {
	background: #f5f5f5;
	padding: 45px 30px;
}
.signup-thumb {
	img {
		max-width: 100%;
	}
}
.signup-form-wrapper {
	padding: 45px 30px 0px 30px;
	background: $white-color;
}
.signup-action {
	margin-bottom: 25px;
}
.sing-buttom {
    button {
        width: 100%;
    }
}
.registered.wrapper {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
.forget-password a {
    color: $body-color;
    font-size: 15px;
	&:hover {
		color: $theme-color;
	}
}
.acount-login {
	margin-bottom: 30px;
	a {
		font-weight: 600;
		color: $theme-color;
		margin-left: 5px;
		text-decoration: underline;
		&:hover {
			color: $heading-color;
		}
	}
}
.signup-text {
	h3 {
		font-size: 28px;
		margin-bottom: 106px;
	}
}
.signup-message {
	img {
		max-width: 100%;
		margin-bottom: 5px;
	}
}
.not-register {
	a {
		font-weight: 600;
		color: $theme-color;
		text-decoration: revert;
		margin-left: 5px;
	}
}

.helper-text.red-text {
	position: absolute;
	bottom: -25px;
	left: 0;
	font-size: 12px;
	color: #e23;
}
.signup-action {
    label {
        margin-left: 5px;
    }
}

.login-modal {
    position: relative;
    z-index: 1;
    @include transition(0.3s);
    &:before {
        position: absolute;
        content: '';
        height: 70px;
        width: 140px;
        background: $white-color;
        opacity: 0.02;
        z-index: -1;
        left: 50%;
        top: 0px;
        transform: translateX(-50%);
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px
    }
    &:hover {
        background: $theme-color;
        &:before {
            opacity: 0.102; 
        }
       .login-modal-icon {
        animation-name: wobble-vertical;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        & i{
            color: $white-color;
        }
       }
	   .login-modal-content-title {
			color: $white-color;
	   }
    }
}
.login-modal-icon {
    display: inline-block;
    & img:last-child {
        display: none;
    }
    & i{
        color: $theme-color;
        font-size: 60px;
        display: inline-block;
    }
}
.login-modal-text-subtitle {
    font-size: 14px;
    color: $white-color;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 2px;
    z-index: 1;
    &:before {
        position: absolute;
        content: '';
        height: 6px;
        width: 100%;
        left: 0px;
        bottom: 1px;
        background: $theme-color;
        z-index: -1;
    }
}
.login-modal-text-title {
    font-size: 60px;
    color: $white-color;
    font-weight: 900;
    text-transform: uppercase;
    @include mq(lg){
        font-size: 56px; 
    }
    @include mq(xs){
        font-size: 42px;
    }
}
.login-modal-text {
    & p {
        color: $body-color;
        line-height: 2;
        font-size: 18px;
    }
}
.login-modal-content-title {
    font-size: 15px;
    font-weight: 700;
    color: $body-color;
    line-height: 22px;
    letter-spacing: 1.5px;
}
.login-modal {
    background: $white-color;
    padding: 40px 60px 35px 60px;
    @include mq(sm){
        padding: 40px 40px 35px 40px;
    }
}
@use "common" as *;
@use "variables" as *;
@use "mixins" as *;

/* 05. Cta */
.kquality-icon {
    height: 120px;
    width: 120px;
    line-height: 120px;
    text-align: center;
    background: $theme-color;
    border-radius: 50%;
    float: left;
    margin-right: 40px;
    @include mq(xs){
        float: inherit; 
        margin-bottom: 30px;
    }
    @include mq(sm){
        margin-bottom: 0px;
        float: left;
    }
    & i{
        font-size: 55px;
        color: $white-color;
        display: inline-block;
        margin-top: 10px;
    }
}
.kquality-text-title {
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
}
.kquality-text {
    & p {
        font-size: 16px;
        line-height: 2;
        margin-bottom: 0px;
    }

}
.kquality-img {
    position: relative;
    display: inline-block;
    & img {
        -webkit-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        &:hover {
            -webkit-filter: grayscale(0%);
            -ms-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
        }
    }
    &:before,
    &:after {
        height: 100%;
        width: 10px;
        top: 0px;
        left: 0px;
        background: $theme-color;
        position: absolute;
        content: '';
        z-index: 1;
    }
    &:after {
        left: 10px;
        background: $white-color;
    }
}
.cta-number {
    &:hover {
        & .cta-number-icon {
            animation-name: wobble-vertical;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
        }
    }
}
.kquality:hover {
    & img {
        animation: scale-up-one infinite 3s linear;
        display: inline-block;
    }
}
.cta-description {
    @include mq(md){
        overflow: hidden;
    }
    @include mq(xs){
        overflow: hidden;
    }
}